<template>
  <!--    Event Form-->
  <validation-observer
    ref="eventForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${event.type===2?'Send Message':updateEventMode?'Update Event Notification':'Create Event Notification'}`"
      :show="eventModalOpened"
      size="md"
      :show-overlay="deletingEvent"
      @onClose="closeEventModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedEventId"
            placeholder="All Events"
            :options="events"
            label-name="title"
            value-name="id"
            @input="populateEventForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteEvent()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetEventForm()"
          />
        </div>
      </b-row>
      <b-row>
        <div class="col-12">
          <lenon-select
            v-model="event.type"
            placeholder="Select Notification Type"
            :options="notificationTypes"
            label-name="title"
            value-name="id"
            :disabled="selectedEventId"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="event.title"
        name="event_title"
        placeholder="Enter title, eg. PTA Meeting"
        rules="required"
        label="Title"
      />
      <template v-if="event.type || selectedEventId">
        <lenon-date-picker
          v-if="event.type===1"
          v-model="event.start"
          :enable-time="true"
          label="Start Date"
          rules="required"
          :show-label="true"
          :show-error="false"
          name="start_date"
        />
        <lenon-date-picker
          v-if="event.type===1"
          v-model="event.end"
          name="end_date"
          :enable-time="true"
          :show-error="false"
          label="End Date"
          :rules="event.all_day?'':'required'"
        />
        <div v-if="event.type===1">
          <div class="mt-1 mb-1">
            <label><strong>By Default, Lenon will only send notifications on event start date,
              we recommend you add more dates by checking the box below.</strong></label>
            <b-form-checkbox
              v-model="extra_dates"
              class="mb-1"
            >
              Add More Dates
            </b-form-checkbox>
            <div
              v-if="extra_dates"
              class="mt-1"
            >
              <b-row
                v-for="(extra_date,index) in event.extra_dates"
                :key="index"
              >
                <div class="col-10">
                  <lenon-date-picker
                    v-model="extra_date.date"
                    :enable-time="true"
                    label="Select Date"
                    rules="required"
                    name="date"
                    :show-error="false"
                  />
                </div>
                <div class="col-2">
                  <div style="margin-top: 25px;display: flex; justify-content: space-between; align-items: center;">
                    <lenon-dropdown
                      icon="TrashIcon"
                    >
                      <b-dropdown-item
                        @click="removeDate(i)"
                      >
                        Yes
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item>
                        No
                      </b-dropdown-item>
                    </lenon-dropdown>
                  </div>

                </div>
              </b-row>
              <b-row>
                <div class="col-12 justify-content-end">
                  <lenon-button
                    :icon-only="true"
                    icon="PlusIcon"
                    variant="outline-success"
                    class="float-right"
                    @onClick="addDate()"
                  />
                </div>
              </b-row>
            </div>
          </div>
        </div>
        <validation-provider
          v-slot="{errors}"
          name="notification channel"
          rules="required"
        >
          <b-form-group label="Select Notification Channels">
            <b-form-checkbox-group
              v-model="event.notification_channels"
              :options="notification_channels"
              inline
            />
            <label v-if="event.notification_channels.includes('Sms')">
              Please note that sending <strong>SMS</strong> will fail if you do not have enough
              balance.</label>
          </b-form-group>
          <small class="text-danger text-capitalize">{{ errors[0] }}</small>
        </validation-provider>
        <strong class="mt-1">Recipients</strong>
        <b-form-checkbox
          v-if="event.type===2"
          v-model="event.individual_students"
          class="my-1"
        >
          Send to specific students' parents
        </b-form-checkbox>
        <div v-if="event.individual_students">
          <lenon-input
            v-model="searchTerm"
            name="contributor"
            label="Search Recipients"
            placeholder="Student ID or Name"
            suffix-icon="SearchIcon"
            @onSuffixIconClick="getStudentDetails"
            @onEnterPressed="getStudentDetails"
          />
          <small
            v-if="recipients.length"
            class="text-danger"
          >Notification will be sent to parents of the following students</small>
          <div class="row mb-1">
            <div
              v-for="(reci,i) in recipients"
              :key="i"
              class="col-12"
            >
              <div
                class="d-flex align-items-center"
              >
                <lenon-profile-photo
                  size="30"
                  :photo="reci.photo"
                />
                <div style="margin-right: 5px;" />
                <b-badge variant="light-primary">
                  {{ reci.name }}
                </b-badge>
                <lenon-button
                  icon-only
                  variant="flat-danger"
                  icon="XIcon"
                  @onClick="clearStudent(i)"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-2 mb-1">
            <lenon-multi-select
              v-model="event.included_classes"
              name="affected_classes"
              label-name="name"
              value-name="id"
              placeholder="Select Classes"
              label="Classes Affected"
              :options="classes"
              rules="required"
            />
          </div>
          <b-form-checkbox
            v-model="allClasses"
            class="mb-1"
          >
            Select All Classes
          </b-form-checkbox>
        </div>
        <lenon-text-area
          v-model="event.included_note"
          label="Custom Message"
          placeholder="Enter message to send to parents of students in affected classes."
          rules="required"
        />
        <div
          v-if="event.type===1"
          class="row"
        >
          <div class="col-12">
            <label>The following message will be sent.</label>
          </div>
          <div class="col-12">
            <small>
              <strong>
                {{ event.included_note || 'YOUR MESSAGE' }}
                <br>
                Starts: <span v-html=" `${event.start?formatDate(event.start):'SELECTED DATE'}`" />,<br>
                Ends: <span v-html="`${event.end?formatDate(event.end): 'SELECTED DATE'}`" />,<br>
                Regards, <br>
                {{ schoolName }}
              </strong>
            </small>
          </div>
        </div>
      </template>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeEventModal()"
          />
          <lenon-button
            v-if="event.type"
            :label="updateEventMode?'Update':event.type===2?'Send Now':'Create'"
            :disabled="invalid || !channelSelected"
            :loading="eventLoading"
            loading-text="Loading..."
            @onClick="updateEventMode?updateEvent():createEvent()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormGroup, BFormCheckbox, BFormCheckboxGroup, BBadge,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import {
  CREATE_CALENDAR_EVENT_M,
  DELETE_CALENDAR_EVENT_M,
  UPDATE_CALENDAR_EVENT_M,
} from '@/graphql/mutations'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'

import logData from '@/libs/log'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'

export default {
  name: 'NotificationSetup',
  components: {
    LenonProfilePhoto,
    ErrorDisplay,
    LenonTextArea,
    LenonMultiSelect,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BBadge,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      notification_channels: [{
        text: 'SMS (charged)',
        value: 'Sms',
      }, {
        text: 'Push Notification (free)',
        value: 'Push',
      }],
      eventModalOpened: false,
      notificationTypes: [{ id: 2, title: 'Instant Message' }, { id: 1, title: 'Event Notification' }],
      updateEventMode: false,
      eventLoading: false,
      deletingEvent: false,
      selectedEventId: null,
      extra_dates: false,
      allClasses: false,
      prevStart: null,
      prevEnd: null,
      prevExtra: [],
      searchTerm: null,
      recipients: [],
      event: {
        id: null,
        title: '',
        start: '',
        end: '',
        all_day: false,
        notify: true,
        type: null,
        individual_students: false,
        students: [],
        included_note: '',
        excluded_note: '',
        notification_channels: [],
        included_classes: [],
        excluded_classes: [],
        extra_dates: [],
        extendedProps: {
          calendar: 'Pending',
        },
      },
    }
  },
  computed: {
    eventType() {
      return this.event.type
    },
    allDay() {
      return this.event.all_day
    },
    channelSelected() {
      return this.event.notify ? (this.event.notification_channels.length > 0) && (this.event.included_classes.length > 0) : true
    },
    calendarOptions() {
      return this.$store.getters['calendarEvents/calendarOptions']
    },
    selectedEvent() {
      return this.events.find(ter => +ter.id === +this.selectedEventId)
    },
    events() {
      return this.eventsData.data
    },
    eventsData() {
      return this.$store.getters['calendarEvents/allEvents']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    clickedEvent() {
      return this.$store.getters['calendarEvents/clickedEvent']
    },
    clickedDate() {
      return this.$store.getters['calendarEvents/clickedDate']
    },
    schoolName() {
      return this.$store.getters['auth/schoolName']
    },
  },
  watch: {
    eventType(type) {
      if (type === 1) {
        this.event.start = this.prevStart || this.event.start
        this.event.end = this.prevEnd || this.event.end
        this.event.extra_dates = this.prevExtra || this.event.extra_dates
      } else if (type === 2) {
        this.prevStart = this.event.start
        this.prevEnd = this.event.end
        this.prevExtra = this.event.extra_dates
        this.event.start = null
        this.event.end = null
        this.event.extra_dates = []
      } else {
        this.event.start = null
        this.event.end = null
        this.event.extra_dates = []
      }
    },
    allClasses(checked) {
      if (checked) {
        this.event.included_classes = this.classes
      } else {
        this.event.included_classes = []
      }
    },
    clickedEvent(event) {
      if (event) {
        this.selectedEventId = event.id
        this.event = {
          ...this
            .selectedEvent,
          included_classes: this.selectedEvent.included_classes.map(c => this.classes.find(cl => +cl.id === +c)),
          excluded_classes: this.selectedEvent.excluded_classes.map(c => this.classes.find(cl => +cl.id === +c)),
        }
        this.extra_dates = this.event.extra_dates.length > 0
        // eslint-disable-next-line no-underscore-dangle
        delete this.event.__typename
        this.updateEventMode = true
        this.eventModalOpened = true
      }
    },
    clickedDate(date) {
      if (date) {
        this.resetEventForm()
        this.event.start = date.toString()
        this.updateEventMode = false
        this.eventModalOpened = true
        this.selectedEventId = null
      }
    },
    allDay(all) {
      if (all) {
        this.event.end = null
      }
    },
    modalOpened(opened) {
      this.eventModalOpened = opened
      if (opened) {
        this.resetEventForm()
        this.selectedEventId = null
      }
    },
  },
  methods: {
    clearStudent(i) {
      this.recipients.splice(i, 1)
    },
    addRecipient(data) {
      const present = this.recipients.some(r => r.id === data.id)
      if (!present) {
        this.recipients.push(data)
      }
    },
    getStudentDetails(search) {
      if (this.searchTerm) {
        this.formLoading = true
        this.$http.get(`students-for-notification?search=${this.searchTerm}`).then(res => {
          this.addRecipient(res.data)
        }).catch(err => {
          this.error = err
        }).finally(() => {
          this.formLoading = false
          this.searchTerm = null
        })
      } else {
        this.showInfo('Enter Student Name or ID')
      }
    },
    formatDate(date) {
      return moment(date)
        .format('MMMM Do YYYY, h:mm a')
    },
    addDate() {
      this.event.extra_dates.push({ date: null })
    },
    removeDate(index) {
      this.event.extra_dates.splice(index, 1)
    },
    // event
    resetEventForm() {
      this.event = {
        id: null,
        title: '',
        start: '',
        end: '',
        all_day: false,
        notify: false,
        individual_students: false,
        students: [],
        included_note: '',
        excluded_note: '',
        notification_channels: [],
        included_classes: [],
        excluded_classes: [],
        extra_dates: [],
        extendedProps: {
          calendar: 'Pending',
        },
      }
      this.searchTerm = null
      this.selectedEventId = null
      this.extra_dates = false
      this.updateEventMode = false
      this.$refs.eventForm.reset()
    },
    populateEventForm(event) {
      if (this.selectedEvent) {
        this.updateEventMode = true
        if (this.selectedEvent.individual_students) {
          this.recipients = [
            ...this
              .selectedEvent.students,
          ]
        }
        this.event = {
          ...this
            .selectedEvent,
          included_classes: this.selectedEvent.included_classes.map(c => this.classes.find(cl => +cl.id === +c)),
          excluded_classes: this.selectedEvent.excluded_classes.map(c => this.classes.find(cl => +cl.id === +c)),
        }
        this.extra_dates = this.event.extra_dates.length > 0
        // eslint-disable-next-line no-underscore-dangle
        delete this.event.__typename
        delete this.event._showDetails
        delete this.event.processing
        delete this.event.failed
      } else {
        this.resetEventForm()
      }
    },
    closeEventModal() {
      this.eventModalOpened = false
      this.$emit('modalClosed')
    },
    updateEvent() {
      this.error = {}
      if (!this.event.id) {
        return
      }
      if (this.event.type === 2) {
        this.event.start = moment().format()
        this.event.end = moment().format()
      }
      if (this.event.individual_students) {
        this.event.students = this.recipients
      }
      this.eventLoading = true
      const event = {
        ...this.event,
        included_classes: this.event.included_classes.map(c => c.id),
        excluded_classes: this.event.excluded_classes.map(c => c.id),
      }
      this.$apollo.mutate({
        mutation: UPDATE_CALENDAR_EVENT_M,
        variables: { input: event },
      })
        .then(res => {
          this.showSuccess('Updated event successfully')
          this.eventLoading = false
          this.$store.commit('calendarEvents/updateEvent', res.data.updateCalendarEvent)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update event')
          this.eventLoading = false
        })
    },
    createEvent() {
      this.error = {}
      this.eventLoading = true
      if (this.event.type === 2) {
        this.event.start = moment().format()
        this.event.end = moment().format()
      }
      if (this.event.individual_students) {
        this.event.students = this.recipients
      }
      const event = {
        ...this.event,
        included_classes: this.event.included_classes.map(c => c.id),
        excluded_classes: this.event.excluded_classes.map(c => c.id),
      }
      this.$apollo.mutate({
        mutation: CREATE_CALENDAR_EVENT_M,
        variables: { input: event },
      })
        .then(res => {
          this.showSuccess('Created event successfully')
          this.eventLoading = false
          this.$store.commit('calendarEvents/addEvent', res.data.createCalendarEvent)
          this.resetEventForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create event')
          this.eventLoading = false
        })
    },
    deleteEvent() {
      if (!this.selectedEventId) {
        this.showInfo('Please select a event')
        return
      }
      this.deletingEvent = true
      this.$apollo.mutate({
        mutation: DELETE_CALENDAR_EVENT_M,
        variables: { id: this.selectedEventId },
      })
        .then(() => {
          this.deletingEvent = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('calendarEvents/removeEvent', this.selectedEventId)
          this.selectedEventId = null
          this.resetEventForm()
        })
        .catch(() => {
          this.showError('Failed to delete event')
          this.deletingEvent = false
        })
    },

  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
