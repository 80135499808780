var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications"},[_c('lenon-list-page',{attrs:{"table-loading":_vm.tableLoading,"remote":true,"title":"Events & Notifications","total-rows":_vm.totalRows,"show-refresh":false,"show-search":true,"show-details":true,"search-placeholder":"Type search + Enter","columns":_vm.columns,"rows":_vm.events},on:{"onPageChange":_vm.handlePageChange,"onPerPageChange":_vm.handlePerPageChange,"onSortChange":_vm.handleSortChange,"onSearch":_vm.handleSearch},scopedSlots:_vm._u([{key:"row-details",fn:function(ref){
var row = ref.row;
return [_c('b-card',[_c('b-row',{staticClass:"mb-2"},[(row.item.individual_students)?_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('strong',[_vm._v("Students ")]),_c('b-row',{staticClass:"px-1"},_vm._l((row.item.students),function(student,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px","margin-right":"3px"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('lenon-profile-photo',{attrs:{"size":"30","photo":student.photo}}),_c('div',{staticStyle:{"margin-right":"5px"}}),_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(student.name)+" ")])],1)])}),0)],1):_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('strong',[_vm._v("Classes ")]),_c('b-row',{staticClass:"px-1"},_vm._l((row.item.included_classes),function(school_class,index){return _c('div',{key:index,staticStyle:{"margin-bottom":"5px","margin-right":"3px"}},[_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.reducedClasses[+school_class])+" ")])],1)}),0)],1)],1)],1)]}},{key:"index",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.index + 1)+" ")]}},{key:"start",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.item.start))+" ")]}},{key:"end",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDate(row.item.end))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{attrs:{"variant":row.item.processing?'success':_vm.getStatusVariant(row.item)}},[_vm._v(" "+_vm._s(row.item.processing?'Processing':_vm.getStatusText(row.item))+" ")])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{attrs:{"variant":row.item.type===2?'secondary':'dark'}},[_vm._v(" "+_vm._s(row.item.type===2?'Instant Message':'Event Notification')+" ")])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('lenon-button',{attrs:{"icon-only":"","variant":'flat-primary',"icon":row.detailsShowing?'EyeOffIcon':'EyeIcon'},on:{"onClick":row.toggleDetails}})]}}])},[_c('template',{slot:"right-extra-header-actions"},[_c('div',{staticClass:"d-flex align-self-center align-items-start"},[_c('lenon-button',{directives:[{name:"responsive",rawName:"v-responsive",value:(_vm.$store.getters['app/largeScreen']),expression:"$store.getters['app/largeScreen']"}],staticClass:"mr-2",attrs:{"icon":"SettingsIcon","label":"Alerts","variant":"outline-primary","tool-tip-text":"Manage alerts to send to parents"},on:{"onClick":function($event){return _vm.showAlertSetupModal()}}}),_c('lenon-button',{directives:[{name:"responsive",rawName:"v-responsive",value:(_vm.$store.getters['app/largeScreen']),expression:"$store.getters['app/largeScreen']"}],attrs:{"icon":"SettingsIcon","label":"Notifications","tool-tip-text":"Manage Events & Notifications (CRUD)"},on:{"onClick":function($event){return _vm.showEventSetupModal()}}}),_c('lenon-dropdown',{directives:[{name:"responsive",rawName:"v-responsive",value:(_vm.$store.getters['app/smallScreen']),expression:"$store.getters['app/smallScreen']"}],attrs:{"left":""}},[_c('lenon-dropdown-item',{attrs:{"icon":"SettingsIcon","text":"Manage Notifications"},on:{"click":function($event){return _vm.showEventSetupModal()}}}),_c('lenon-dropdown-item',{attrs:{"icon":"SettingsIcon","text":"Manage Alerts"},on:{"click":function($event){return _vm.showAlertSetupModal()}}})],1)],1)])],2),_c('notification-setup',{attrs:{"modal-opened":_vm.eventModalOpened},on:{"modalClosed":function($event){return _vm.closeModal('event')}}}),_c('alert-setup',{attrs:{"modal-opened":_vm.alertModalOpened},on:{"modalClosed":function($event){return _vm.closeModal('alert')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }