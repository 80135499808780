<template>
  <div class="notifications">
    <lenon-list-page
      :table-loading="tableLoading"
      :remote="true"
      title="Events & Notifications"
      :total-rows="totalRows"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      search-placeholder="Type search + Enter"
      :columns="columns"
      :rows="events"
      @onPageChange="handlePageChange"
      @onPerPageChange="handlePerPageChange"
      @onSortChange="handleSortChange"
      @onSearch="handleSearch"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start">
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            icon="SettingsIcon"
            label="Alerts"
            class="mr-2"
            variant="outline-primary"
            tool-tip-text="Manage alerts to send to parents"
            @onClick="showAlertSetupModal()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            icon="SettingsIcon"
            label="Notifications"
            tool-tip-text="Manage Events & Notifications (CRUD)"
            @onClick="showEventSetupModal()"
          />
          <lenon-dropdown
            v-responsive="$store.getters['app/smallScreen']"
            left
          >
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Manage Notifications"
              @click="showEventSetupModal()"
            />
            <lenon-dropdown-item
              icon="SettingsIcon"
              text="Manage Alerts"
              @click="showAlertSetupModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #row-details="{row}">
        <b-card>
          <b-row
            class="mb-2"
          >
            <b-col
              md="12"
              class="mb-1"
              v-if="row.item.individual_students"
            >
              <strong>Students </strong>
              <b-row class="px-1">
                <div
                  v-for="(student,index) in row.item.students"
                  :key="index"
                  style="margin-bottom: 5px; margin-right: 3px;"
                >
                  <div
                      class="d-flex align-items-center"
                  >
                    <lenon-profile-photo
                        size="30"
                        :photo="student.photo"
                    />
                    <div style="margin-right: 5px;" />
                    <b-badge variant="light-primary">
                      {{ student.name }}
                    </b-badge>
                  </div>
                </div>
              </b-row>
            </b-col>
            <b-col
                v-else
                md="12"
                class="mb-1"
            >
              <strong>Classes </strong>
              <b-row class="px-1">
                <div
                    v-for="(school_class,index) in row.item.included_classes"
                    :key="index"
                    style="margin-bottom: 5px; margin-right: 3px;"
                >
                  <b-badge variant="light-success">
                    {{ reducedClasses[+school_class] }}
                  </b-badge>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
      <template #start="{row}">
        {{ formatDate(row.item.start) }}
      </template>
      <template #end="{row}">
        {{ formatDate(row.item.end) }}
      </template>
      <template #status="{row}">
        <b-badge :variant="row.item.processing?'success':getStatusVariant(row.item)">
          {{ row.item.processing?'Processing':getStatusText(row.item) }}
        </b-badge>
      </template>
      <template #type="{row}">
        <b-badge :variant="row.item.type===2?'secondary':'dark'">
          {{ row.item.type===2?'Instant Message':'Event Notification' }}
        </b-badge>
      </template>
      <template #actions="{row}">
        <lenon-button
          icon-only
          :variant="'flat-primary'"
          :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
          @onClick="row.toggleDetails"
        />
      </template>
    </lenon-list-page>
    <notification-setup
      :modal-opened="eventModalOpened"
      @modalClosed="closeModal('event')"
    />
    <alert-setup
      :modal-opened="alertModalOpened"
      @modalClosed="closeModal('alert')"
    />
  </div>
</template>

<script>
import {
  BRow, BBadge, BCol, BCard,
} from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import { GET_CALENDAR_EVENTS_Q } from '@/graphql/queries'
import NotificationSetup from '@/views/admin/notifications/EventSetup.vue'
import AlertSetup from '@/views/admin/notifications/AlertSetup.vue'
import moment from 'moment'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'

export default {
  name: 'Notifications',
  components: {
    LenonProfilePhoto,
    LenonDropdownItem,
    LenonDropdown,
    NotificationSetup,
    LenonButton,
    LenonListPage,
    BBadge,
    BRow,
    BCol,
    BCard,
    AlertSetup,
  },
  mixins: [showToast],
  data() {
    return {
      eventModalOpened: false,
      currentPage: null,
      perPage: 5,
      searchTerm: null,
      initializing: false,
      sort: {
        column: 'created_at',
        order: 'desc',
      },
      tableLoading: false,
      alertModalOpened: false,
      reducedClasses: {},
    }
  },
  computed: {
    totalRows() {
      return this.eventsData?.paginatorInfo?.count
    },
    events() {
      return this.eventsData.data
    },
    eventsData() {
      return this.$store.getters['calendarEvents/allEvents']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    columns() {
      const show = this.$store.getters['app/isLargeScreen']
      return [
        {
          key: 'index',
          label: '#',
        },
        {
          label: 'Title',
          key: 'title',
          sortable: true,
        },
        {
          label: 'Message',
          key: 'included_note',
          sortable: true,
        },
        {
          label: 'Start Date',
          key: 'start',
          sortable: show,
        },
        {
          label: 'End Date',
          key: 'end',
          sortable: show,
        },
        {
          label: 'Type',
          key: 'type',
          sortable: show,
        },
        {
          label: 'Notification Status',
          key: 'status',
        },
        {
          label: 'Actions',
          key: 'actions',
        },
      ]
    },
  },
  mounted() {
    this.fetchCalendarEvents()
    this.reduceCurrentClasses()
  },
  methods: {
    // rowClass(item, type) {
    //   if (type === 'row') {
    //     if (item.type === 2) {
    //       return 'table-success'
    //     }
    //     // if (item.status === 2) {
    //     //   return 'table-warning'
    //     // }
    //     // return 'table-danger'
    //   }
    // },
    formatDate(date) {
      return moment(date).format('lll')
    },
    getStatusVariant(item) {
      const props = item.extendedProps
      const { failed } = item
      const { type } = item
      if (type === 2) {
        return 'success'
      }
      if (failed) {
        return 'danger'
      }
      if (props.calendar === 'Processed') {
        return 'success'
      }
      return 'warning'
    },
    getStatusText(item) {
      const props = item.extendedProps
      const { failed } = item
      const { type } = item
      if (type === 2) {
        return 'Notifications Sent'
      }
      if (failed) {
        return 'No Notification Sent'
      }
      if (props.calendar === 'Processed') {
        return 'Notifications Sent'
      }
      return 'Waiting to Send'
    },
    reduceCurrentClasses() {
      this.reducedClasses = this.classes.reduce((cumulatedValue, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        cumulatedValue[+currentValue.id] = currentValue.name
        return cumulatedValue
      }, {})
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'event':
          this.eventModalOpened = false
          break
        case 'alert':
          this.alertModalOpened = false
          break
      }
    },
    showAlertSetupModal() {
      this.alertModalOpened = true
    },
    showEventSetupModal() {
      this.eventModalOpened = true
    },
    fetchCalendarEvents() {
      this.tableLoading = (this.events?.length < 1) || true
      this.$apollo.query({
        query: GET_CALENDAR_EVENTS_Q,
        variables: {
          input: {
            search: this.searchTerm,
            sort: this.sort,
            per_page: this.perPage,
            page: this.currentPage,
          },
        },
      }).then(res => {
        this.tableLoading = false
        if (!res.errors) {
          this.$store.commit('calendarEvents/setEvents', res.data.getCalendarEvents)
          this.$store.commit('calendarEvents/setAlerts', res.data.alerts)
        } else {
          // this.showError('Failed to load events, please contact support if problem persists')
        }
      }).catch(e => {
        this.tableLoading = false
      })
    },
    handleSearch(search) {
      this.searchTerm = search
      this.fetchCalendarEvents()
    },
    handlePageChange(page) {
      this.currentPage = page
      this.fetchCalendarEvents()
    },
    handlePerPageChange(perPage) {
      if (!this.initializing) {
        this.perPage = perPage
        this.fetchCalendarEvents()
      }
    },
    handleSortChange(sort) {
      // this.sort.column = sort.sortBy
      this.sort.order = sort.sortDesc ? 'desc' : 'asc'
      this.fetchCalendarEvents()
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
